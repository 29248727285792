import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { svgImages } from "../../../assets/images";
import { format } from "date-fns";

import { icons } from "../../../assets/icons";
import styled from "styled-components";
import { Label } from "../../../components/Label";
import { Product } from "../../../components/Product";
import { Text } from "../../../components/Text";
import { theme } from "../../../components/theme";
import { useCategoriesProducts } from "../../../hooks/Products";
import { TOrder } from "../../../types/Order";
import { TProduct } from "../../../types/Product";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading";
import { useInfiniteHits } from "react-instantsearch-hooks-web";
import i18next from "i18next";
import { useAppState } from "../../../contexts";

interface Props {
  order?: TOrder;
  selectedTag: string;
  selectedCategory: string;
  searchQuery: string;
  orderByFilter: string;
  isConnectedUser: boolean;
}

function orderAlgoliaProducts(hits: any[], lang: string) {
  const sortedHits = hits
    .map((phit) => ({
      ...phit,
      best_before: phit?.best_before
        ? format(new Date(phit.best_before), "MM/dd/yyyy")
        : null,
      promotion:
        phit?.member_price > 0
          ? Math.round(
              ((phit.price - phit.regular_price) / phit.regular_price) * 100
            )
          : 15,
    }))
    // Sort items without inventory first, then sort by `hit.copy.fr.name`
    .sort((a, b) => {
      const hasInventoryA = !!a.inventory;
      const hasInventoryB = !!b.inventory;

      // Move items without `inventory` to the beginning
      if (!hasInventoryA && hasInventoryB) return -1;
      if (hasInventoryA && !hasInventoryB) return 1;

      // Safely access `copy[lang]?.name`
      const nameA = a.copy?.[lang]?.name || ""; // Default to empty string if undefined
      const nameB = b.copy?.[lang]?.name || "";

      // Sort alphabetically by `hit.copy[lang].name`
      return nameA.localeCompare(nameB);
    });

  return sortedHits.filter((product: TProduct) => product.published);
}
export const Results: React.FC<Props> = ({
  order,
  selectedTag,
  selectedCategory,
  searchQuery,
  orderByFilter,
  isConnectedUser,
}) => {
  const { t } = useTranslation();
  let lang: string = i18next.language;
  const { locale } = useAppState();

  const [lang1] = locale.split("-");

  const { hits, showMore, isLastPage } = useInfiniteHits();
  const history = useHistory();

  const [products, setProducts] = useState<TProduct[]>([]);
  const [displayProducts, setDisplayProducts] = useState<TProduct[]>([]);
  const { data, fetchNextPage, hasNextPage, isLoading } = useCategoriesProducts(
    20,
    selectedCategory,
    selectedTag,
    lang,
    orderByFilter
  );

  useEffect(() => {
    if (searchQuery) {
      const addPromoField = orderAlgoliaProducts(hits, lang1);

      setProducts(addPromoField);
    }
  }, [searchQuery, hits]);

  const productsFromDb =
    data && data.pages.flatMap((page) => page.products).map((p) => p);

  useEffect(() => {
    if (!searchQuery && selectedCategory) {
      if (isConnectedUser) {
        setProducts(productsFromDb ? productsFromDb : []);
      } else {
        setProducts(productsFromDb ? productsFromDb.slice(0, 8) : []);
      }
    }
  }, [searchQuery, selectedCategory, data]);

  useEffect(() => {
    setDisplayProducts(products);
  }, [selectedTag, products]);

  const onSelectProduct = (product: TProduct) =>
    history.push({
      pathname: `/products/${product.pid}`,
      state: { prevPath: "/products" },
    });

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <ResultsWrapper>
      {displayProducts && displayProducts.length > 0 && (
        <ProductsWrapper>
          {displayProducts.map((product) => {
            return (
              <Product
                isPreOrder={!!order && order.status === "open" ? false : true}
                withBanner={true}
                key={product.pid}
                onSelectProduct={onSelectProduct}
                product={product}
                order={order}
              />
            );
          })}
        </ProductsWrapper>
      )}

      {displayProducts && displayProducts.length > 0 && (
        <ButtonWrapper>
          {searchQuery && !isLastPage ? (
            <Button size="lg" secondary onClick={showMore}>
              {t("Catalog.search.more")}
            </Button>
          ) : (
            <div>
              {hasNextPage && (
                <Button secondary onClick={() => fetchNextPage()}>
                  {t("Prime.button.showmore")}
                </Button>
              )}
            </div>
          )}

          <icons.arrowTop onClick={scrollTop} />
        </ButtonWrapper>
      )}

      {(!displayProducts || displayProducts.length === 0) && !isLoading && (
        <EmptyWrapper>
          <svgImages.emptyResult />
          <EmptyTitleLabel weight={"black"}>
            {t("Catalog.market.empty.title")}
          </EmptyTitleLabel>
          <EmptyText weight={"bold"}>
            {t("Catalog.market.empty.text")}
          </EmptyText>
        </EmptyWrapper>
      )}
      {isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
    </ResultsWrapper>
  );
};

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  width: 100%;
`;

const ProductsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${theme.spacing[1]};
  align-self: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: calc(2 * 256px + 20px);
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    width: calc(3 * 256px + 2 * 20px);
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    width: calc(4 * 256px + 3 * 20px);
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing[3]};
  gap: ${theme.spacing[1]};

  TODO > button {
    margin-right: ${theme.spacing[2]};
  }

  svg {
    cursor: pointer;
  }
`;

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;

  svg {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 450px;
  }
`;

const EmptyTitleLabel = styled(Label)`
  font-size: ${theme.font.size.xl};
  line-height: ${theme.font.size.xl};
  text-align: center;
  letter-spacing: 0.02em;
  color: ${theme.colors.base.secondary};
  text-transform: uppercase;

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xxxl};
    line-height: ${theme.font.size.xxxl};
  }
`;

const EmptyText = styled(Text)`
  padding-bottom: ${theme.spacing[1]};
  font-size: ${theme.font.size.xl};
  line-height: ${theme.font.size.xl};
  text-align: center;
  letter-spacing: 0.02em;
  color: ${theme.colors.base.primary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xxxl};
    line-height: ${theme.font.size.xxxl};
  }
`;

const LoadingWrapper = styled.div`
  > div {
    position: fixed;
  }
`;
