import { subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppState } from "../../contexts";
import { useDelivery } from "../../hooks/Delivery";
import { useNextDeliveries } from "../../hooks/NextDeliveries";
import { useCart, useProcessingOrders } from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { useUser } from "../../hooks/Users";
import { formatTimeslotDay, formatTimeslotHours } from "../../utils/Date";
import {
  formatCurrency,
  formatDateShort,
  formatNumber,
  formatShortDate,
} from "../../utils/i18n";
import { Button } from "../Button";
import { Label } from "../Label";
import { theme } from "../theme";
import { PlanItem } from "./PlanItem";
import { Coupon } from "../Coupon";
import { useLocation } from "react-router-dom";
import { useCouponUser } from "../../hooks/Coupons";
import { getCouponType } from "../../utils/Plans";

interface Props {
  showCreationDate: boolean;
  showDeliveryPrice: boolean;
  showAction: boolean;
  onModifyPlans: () => void;
  onModifyDelivery: () => void;
}

export const OrderSummary: React.FC<Props> = ({
  showCreationDate,
  showDeliveryPrice,
  showAction,
  onModifyPlans,
  onModifyDelivery,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [displaySwitchCoupon, setSwitchCoupon] = useState(false);
  const [lang] = locale.split("-");
  const { data: subscription } = useSubscription();
  const { data: delivery } = useDelivery();
  const { data: user } = useUser();
  const { data: order } = useProcessingOrders();
  const { data: cart } = useCart(order?.oid);
  const { data: currentCoupon } = useCouponUser();
  const { data: nextDeliveries } = useNextDeliveries();
  const path = useLocation();

  const isSignupFlow = path.pathname.startsWith("/sign-up");
  const pupDelivery = delivery && delivery?.entity_type === "PUP";
  const processingDate =
    order && order?.status !== "skipped"
      ? order.processing_date
      : nextDeliveries?.next_processing_date;
  const populateDate = processingDate
    ? subDays(new Date(processingDate), 2)
    : null;
  const populateDateFormatted = populateDate
    ? new Intl.DateTimeFormat(locale, {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(populateDate))
    : null;
  const nextDeliveryDate =
    order && order.status !== "skipped"
      ? order.delivery_date
      : nextDeliveries?.next_delivery_date;
  const nextDeliveryDateFormatted = nextDeliveryDate
    ? new Intl.DateTimeFormat(locale, {
        weekday: "long",
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(nextDeliveryDate))
    : null;

  useEffect(() => {
    if (currentCoupon) {
      setSwitchCoupon(!!currentCoupon);
    }
  }, [currentCoupon]);

  return (
    <SummaryContainer>
      {displaySwitchCoupon && currentCoupon ? (
        <>
          <RowWrapper>
            <RowLabel size="mdx">
              {t("Dashboard.order.coupon.actif")}{" "}
              <TextButton onClick={() => setSwitchCoupon(false)}>
                ( {t("Dashboard.order.coupon.switch")})
              </TextButton>
            </RowLabel>
            <RedLabel size="xl">{currentCoupon.code}</RedLabel>
          </RowWrapper>

          <RowWrapper>
            <RowLabel size="mdx">{t("Dashboard.order.coupon.amount")}</RowLabel>
            <RedLabel size="xl">
              -
              {`${formatNumber(
                locale,
                currentCoupon.amount / 100
              )}${getCouponType(currentCoupon.type)}`}
            </RedLabel>
          </RowWrapper>

          {currentCoupon?.expiry_date && (
            <RowWrapper>
              <RowLabel size="mdx">
                {t("Dashboard.order.coupon.expiry")}
              </RowLabel>
              <RedLabel size="xl">
                {formatDateShort(new Date(currentCoupon.expiry_date), locale)}
              </RedLabel>
            </RowWrapper>
          )}
        </>
      ) : (
        <RowWrapper>
          {!isSignupFlow && (
            <Coupon
              onChangeCoupon={() => {}}
              bgColor={theme.colors.base.white}
            />
          )}
        </RowWrapper>
      )}
      {showCreationDate && populateDateFormatted && (
        <>
          <Separator />

          <RowWrapper>
            <RowLabel size="mdx">{t("Dashboard.order.populate")}</RowLabel>
            <RedLabel size="xl">{populateDateFormatted}</RedLabel>
          </RowWrapper>
        </>
      )}{" "}
      <Separator />
      {subscription && subscription.plans && (
        <PlanWrapper>
          {subscription.plans.map((plan) => (
            <PlanItem
              locale={locale}
              key={plan.id}
              name={plan.copy[lang]?.name}
              tag={plan.copy[lang]?.tagline}
              quantity={plan.persons}
              min_price={plan.min_price}
              max_price={plan.max_price}
            />
          ))}
        </PlanWrapper>
      )}
      <Separator />
      <RowWrapper>
        <RowLabel size="mdx">{t("Signup.confirm.delivery")}</RowLabel>
        {/* <ModifyLabel size="md" onClick={onModifyDelivery}>
          {t("Signup.confirm.modify")}
        </ModifyLabel> */}
      </RowWrapper>
      {delivery && (
        <>
          <DeliveryComplementLabel size="md">
            {subscription?.frequence === 7
              ? t("Signup.step3.frequency.weekly")
              : t("Signup.step3.frequency.bimonthly")}
          </DeliveryComplementLabel>
          <DeliveryWrapper>
            <AddressWrapper>
              <BaseBoldLabel size="md">
                {pupDelivery ? delivery.name : t("Signup.step3.delivery.house")}
              </BaseBoldLabel>
              <BaseLabel size="md">
                {pupDelivery ? delivery.address : user?.address}
              </BaseLabel>
              <BaseLabel size="md">
                {pupDelivery ? delivery.address2 : user?.address2}
              </BaseLabel>
              <BaseLabel size="md">
                {pupDelivery
                  ? `${delivery.zip_code ? delivery.zip_code : " "}${
                      delivery.province
                    }`
                  : `${user?.zip_code} ${user?.province}`}
              </BaseLabel>
            </AddressWrapper>
            <ColumnWrapper>
              <BaseBoldLabel size="md">
                {formatTimeslotDay(delivery?.day, locale, "long")}
              </BaseBoldLabel>
              <BaseLabel size="md">
                {formatTimeslotHours(
                  delivery?.day,
                  delivery?.start_time,
                  delivery?.end_time,
                  locale,
                  "short"
                )}
              </BaseLabel>
            </ColumnWrapper>
          </DeliveryWrapper>
          {nextDeliveryDateFormatted && showCreationDate && (
            <DeliveryWrapper>
              <BaseLabel size="md">
                {t("Signup.confirm.delivery.next")}
              </BaseLabel>

              <DeliveryComplementLabel size="md">
                {nextDeliveryDateFormatted.charAt(0).toUpperCase() +
                  nextDeliveryDateFormatted.slice(1)}
              </DeliveryComplementLabel>
            </DeliveryWrapper>
          )}
        </>
      )}
      <Separator />
      {showDeliveryPrice && (
        <>
          <RowWrapper>
            <RowLabel size="mdx">{t("Signup.confirm.delivery")}</RowLabel>
            <DeliveryPriceLabel size="md">
              {pupDelivery
                ? t("Signup.step3.delivery.collect.price")
                : t("Signup.step3.delivery.house.price")}
            </DeliveryPriceLabel>
          </RowWrapper>
          {/* <Text size="sm">*{t("Cart.freeShipping")}</Text> */}
          <Separator />
        </>
      )}
      {cart && (
        <>
          <RowLabel size="mdx">{t("Cart.label")}</RowLabel>
          <CartWrapper>
            <RowWrapper>
              <BaseLabel size="md">{t("Cart.subtotal")}</BaseLabel>
              <BaseBoldLabel size="md">
                {formatCurrency(locale, cart.subtotal_price / 100)}
              </BaseBoldLabel>
            </RowWrapper>
            {(Number(cart?.credit_stripe) > 0 ||
              Number(cart?.credit_stripe) < 0) && (
              <RowWrapper>
                <BaseLabel size="md">{t("Cart.credit-stripe")}</BaseLabel>
                <BaseBoldLabel size="md">
                  {formatCurrency(locale, cart.credit_stripe / 100)}
                </BaseBoldLabel>
              </RowWrapper>
            )}
            {cart?.discount && (
              <RowWrapper>
                <BaseLabel size="md">{t("Cart.discount-admin")}</BaseLabel>
                <BaseBoldLabel size="md">{cart.discount}</BaseBoldLabel>
              </RowWrapper>
            )}
            {(Number(cart?.credit_left) > 0 ||
              Number(cart?.credit_left) < 0) && (
              <RowWrapper>
                <BaseLabel size="md">{t("Cart.credit-admin")}</BaseLabel>
                <BaseBoldLabel size="md">
                  {formatCurrency(locale, cart.credit_left / 100)}
                </BaseBoldLabel>
              </RowWrapper>
            )}

            <RowWrapper>
              <BaseLabel size="md">{t("Signup.confirm.delivery")}</BaseLabel>
              <BaseBoldLabel size="md">
                {formatCurrency(locale, cart.shipping_price / 100)}
              </BaseBoldLabel>
            </RowWrapper>
            <RowWrapper>
              <BaseLabel size="md">{t("Cart.tips")}</BaseLabel>
              <BaseBoldLabel size="md">
                {formatCurrency(locale, cart.tips / 100)}
              </BaseBoldLabel>
            </RowWrapper>
            <RowWrapper>
              <BaseLabel size="md">{t("Cart.tax.tps")}</BaseLabel>
              <BaseBoldLabel size="md">
                {formatCurrency(locale, cart.tax1 / 100)}
              </BaseBoldLabel>
            </RowWrapper>
            <RowWrapper>
              <BaseLabel size="md">{t("Cart.tax.tvq")}</BaseLabel>
              <BaseBoldLabel size="md">
                {formatCurrency(locale, cart.tax2 / 100)}
              </BaseBoldLabel>
            </RowWrapper>
            <RowWrapper>
              <BaseLabel size="md">{t("Cart.total")}</BaseLabel>
              <BaseBoldLabel size="md">
                {formatCurrency(locale, cart.total_price / 100)}
              </BaseBoldLabel>
            </RowWrapper>
            <Separator />

            <RowWrapper>
              <SavingLabel size="md">
                {subscription?.is_prime
                  ? t("Cart.prime.savings")
                  : t("Cart.not.prime.savings")}
              </SavingLabel>
              <SavingLabel size="md">
                {formatCurrency(locale, cart.savings / 100)}
              </SavingLabel>
            </RowWrapper>
          </CartWrapper>
          <Separator />
        </>
      )}
      {/* <RowWrapper>
        <RowLabel size="mdx">
          {cart ? t("Cart.total") : ReactHtmlParser(t("Signup.confirm.total"))}
        </RowLabel>
        {cart && (
          <PriceLabel size="lg">
            {formatCurrency(locale, cart.total_price / 100)}
          </PriceLabel>
        )}
        {!cart && subscription && (
          <PriceLabel size="lg">
            {`${formatNumber(
              locale,
              subscription.min_price / 100
            )}-${formatNumber(locale, subscription.max_price / 100)} $`}
          </PriceLabel>
        )}

      </RowWrapper> */}
      {/* {!cart && subscription && (
        <Text size="sm">
          {ReactHtmlParser(t("Signup.confirm.total.explanation"))}
        </Text>
      )} */}
      {/* <RowLabel size="mdx">
        {ReactHtmlParser(t("Signup.confirm.explanation"))}
      </RowLabel> */}
      {showAction && (
        <ActionWrapper>
          <Button to="/cart">{t("Dashboard.see.cart")}</Button>
        </ActionWrapper>
      )}
    </SummaryContainer>
  );
};

const TextButton = styled.button`
  all: unset; /* Removes all default button styles */
  cursor: pointer; /* Ensures the button looks interactive */
  color: inherit; /* Inherits the text color of its parent */
  font: inherit; /* Inherits font family, size, weight, etc. */
  &:hover {
    text-decoration: underline; /* Adds underline on hover */
  }
  font-size: ${theme.font.size.sm};
  line-height: ${theme.font.size.sm};
  font-family: ${theme.font.family.secondary};
`;

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  background: ${theme.colors.base.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanWrapper = styled.div`
  margin: ${theme.spacing[1]} 0;
`;

const DeliveryWrapper = styled(RowWrapper)`
  align-items: flex-start;
  margin-top: ${theme.spacing[0.5]};
`;

const AddressWrapper = styled(ColumnWrapper)`
  flex: 1;
  margin-right: ${theme.spacing[2]};
`;

const RowLabel = styled(Label)`
  line-height: ${theme.font.size.lg};
  /* font-weight: ${theme.font.weight.bold}; */
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.secondary};
`;

const RedLabel = styled(Label)`
  margin-left: ${theme.spacing[1]};
  line-height: ${theme.font.size.xxl};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
  white-space: nowrap;
`;

const ModifyLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
  cursor: pointer;
`;

const BaseLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-family: ${theme.font.family.secondary};
`;

const DeliveryComplementLabel = styled(BaseLabel)`
  color: ${theme.colors.base.secondary};
`;

const BaseBoldLabel = styled(BaseLabel)`
  font-weight: ${theme.font.weight.bold};
`;
const SavingLabel = styled(BaseBoldLabel)`
  color: ${theme.colors.base.primary};
`;
const BaseCouponLabel = styled(BaseLabel)`
  font-weight: ${theme.font.weight.bold};
  color: green;
`;

const DeliveryPriceLabel = styled(Label)`
  line-height: ${theme.font.size.mdx};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
`;

const PriceLabel = styled(Label)`
  line-height: ${theme.font.size.xl};
  font-weight: ${theme.font.weight.bold};
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: ${theme.spacing[1]} 0;
  background: ${theme.colors.base.secondary};
`;

const CartWrapper = styled(ColumnWrapper)`
  margin-top: ${theme.spacing[0.5]};
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme.spacing[1]};

  a {
    width: 100%;
    text-align: center;
  }
`;
